import React from "react" 
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const VideothequePage = () => (
  <Layout>
    <SEO title="Videothèque" />
   <div class="md:mx-40 md:my-5 mx-3">
      <div class="grid grid-cols-1 mt-8 gap-5">
        <h1 class="text-center text-2xl font-bold uppercase">DÉCOUVREZ NOTRE VIDÉOTHÈQUE POUR QUE VOUS VISIONNEZ PLUS NOTRE TRAVAIL</h1>
        <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
      </div> 
      <div class="grid grid-cols-1 mt-8 gap-2">
        <div class="video-wrapper"> 
          <div class="video">
              <iframe width="350" height="197" title="a" src="https://www.youtube.com/embed/3WuC_Ktzo8Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
             <iframe width="350" height="197" title="b" src="https://www.youtube.com/embed/q_L6lzz6mo8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
              <iframe width="350" height="197" title="c" src="https://www.youtube.com/embed/LZwb0nP4mls" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
              <iframe width="350" height="197" title="d" src="https://www.youtube.com/embed/eYiTaG3miM8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
             <iframe width="350" height="197" title="c" src="https://www.youtube.com/embed/OoVN6juIdNQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
             <iframe width="350" height="197" title="f" src="https://www.youtube.com/embed/Q8UtPCKOyr0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
             <iframe width="350" height="197" title="g" src="https://www.youtube.com/embed/1j5y-I47F1c" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
             <iframe width="350" height="197" title="h" src="https://www.youtube.com/embed/yyKm199HvfM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
            <iframe width="350" height="197" title="i" src="https://www.youtube.com/embed/bsq_rJwk3Wc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
            <iframe width="350" height="197" title="j" src="https://www.youtube.com/embed/x74sxjP6xB0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
             <iframe width="350" height="197" title="k" src="https://www.youtube.com/embed/Lpc3oPXM3eU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
            <iframe width="350" height="197" title="l" src="https://www.youtube.com/embed/FvYu_uafdlY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
             <iframe width="350" height="197" title="m" src="https://www.youtube.com/embed/cVQBEt_Y0Sk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
            <iframe width="350" height="197" title="n" src="https://www.youtube.com/embed/91JBpRhVbwM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
             <iframe width="350" height="197" title="o" src="https://www.youtube.com/embed/eQN9kV1gwjk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
            <iframe width="350" height="197" title="p" src="https://www.youtube.com/embed/-rYe0t8jp-M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
             <iframe width="350" height="197" title="q" src="https://www.youtube.com/embed/WVGuEuqpRIw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
            <iframe width="350" height="197" title="r" src="https://www.youtube.com/embed/IN39DEtRuYc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
            <iframe width="350" height="197" title="s" src="https://www.youtube.com/embed/4p95bvrCCq8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
            <iframe width="350" height="197" title="t" src="https://www.youtube.com/embed/2hkP7qCIvTs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video">
            <iframe width="350" height="197" title="u" src="https://www.youtube.com/embed/jsAPGMNjMXE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          </div> 
      </div>
    </div>
   </Layout>
)
export default VideothequePage